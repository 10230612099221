/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@400;500;600;700;800&display=swap');

*:not(:has(#certificado)){

  .modal{
    .card{
      max-width: 500px;
    }
  }


    .swiper-pagination-bullet{
      background-color: rgb(248, 249, 249) !important;
      width: 1rem;
      height: 1rem;
      border: 1px solid #636666;
      opacity: 1;
    } 
    .swiper-pagination-bullet-active{
      background-color: #01616e !important;
      width: 1rem;
      height: 1rem;
      border: 1px solid #01616e;
    }

  body {
    background: #bebebe !important;
  }

  .modal-wrapper{
    display: none;
  }
  
  * {
    font-family: 'Open Sans', sans-serif;
  }
  ion-app {
    margin: auto;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #DDECE6;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #cbe9e5;
  }


  :root {
    --gradient: linear-gradient(90deg, rgba(255, 125, 33, 1) 0%, rgba(255, 56, 112, 1) 46%, rgba(215, 53, 144, 1) 100%);
    --bg-purple: #c65ae0;
    --laranja: #ff801d;
  }

  .bg-gray {
    background-color: #ECECEC !important;
  }

  .bg-lime{
    background-color: #DDECE6;
  }

  .footer-background {
    backdrop-filter: none !important;
    -webkit-backdrop-filter: none !important;
  }

  ion-toolbar {
    height: 3.5rem;
    --background: #F6F7F9;
  }

  html,
  body {
    scroll-behavior: smooth;
  }

  .backdrop-no-scroll {
    background: #444;
  }

  input {
    background: #fff;
  }

  ion-menu-button {
    color: #555;
  }

  .toolbar-container,
  .toolbar-background {
    background: #fff !important;
  }

  .thin {
    font-weight: 300;
  }

  .semi-bold {
    font-weight: 600;
  }

  .bold {
    font-weight: 700 !important;
  }

  .extra-bold {
    font-weight: 800;
  }

  .flex {
    display: flex;
  }

  .centralize {
    justify-content: center;
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-evenly {
    justify-content: space-evenly;
  }

  .justify-around {
    justify-content: space-around;
  }

  .column {
    flex-direction: column;
  }

  .uppercase {
    text-transform: uppercase !important;
  }

  .small-font {
    font-size: 0.9rem;
  }

  .small2-font {
    font-size: 0.8rem;
  }

  .extra-small-font {
    font-size: 0.55rem !important;
  }

  .nowrap {
    white-space: nowrap;
  }

  .medium-font {
    font-size: 1.2rem !important;
  }

  .large-font {
    font-size: 1.6rem;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }


  .w-full {
    width: 100% !important;
  }

  .w-90 {
    width: 90% !important;
  }

  .w-80 {
    width: 80% !important;
  }

  .w-75 {
    width: 75% !important;
  }

  .w-70 {
    width: 70% !important;
  }

  .w-65 {
    width: 65% !important;
  }

  .w-60 {
    width: 60% !important;
  }

  .w-55 {
    width: 55% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-45 {
    width: 45% !important;
  }

  .w-40 {
    width: 40% !important;
  }

  .w-30 {
    width: 30% !important;
  }

  .w-20 {
    width: 20% !important;
  }

  .w-10 {
    width: 10% !important;
  }

  .w-auto {
    width: auto !important;
  }

  .mw-2 {
    max-width: 2rem;
  }

  .mw-3 {
    max-width: 3rem;
  }

  .mw-4 {
    max-width: 4rem;
  }

  .mw-100rem {
    max-width: 100rem !important;
  }

  .mh-1rem {
    max-height: 1rem !important;
  }

  .mh-1-5rem {
    max-height: 1.5rem !important;
  }

  .mh-2rem {
    max-height: 2rem !important;
  }

  .mh-3rem {
    max-height: 3rem !important;
  }

  .mh-4rem {
    max-height: 4rem !important;
  }


  .white {
    color: #fff !important;
  }

  .grey {
    color: #999 !important;
  }

  .mt-4rem {
    margin-top: 4rem !important;
  }

  .mt-6rem {
    margin-top: 6rem !important;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .mb-3 {
    margin-bottom: 3rem;
  }

  .mb-4 {
    margin-bottom: 4rem;
  }

  .mb-5 {
    margin-bottom: 5rem;
  }

  .mb-6 {
    margin-bottom: 6rem;
  }

  .mb-7 {
    margin-bottom: 7rem;
  }

  .mb-8 {
    margin-bottom: 8rem;
  }

  .mb-9 {
    margin-bottom: 9rem;
  }

  .mb-10 {
    margin-bottom: 10rem;
  }

  .ml-2 {
    margin-left: 2rem !important;
  }

  .ml-3 {
    margin-left: 3rem !important;
  }

  .ml-4 {
    margin-left: 4rem !important;
  }

  .ml-5 {
    margin-left: 5rem !important;
  }

  .minus-mt-2 {
    margin-top: -2rem !important;
  }

  .minus-mt-5 {
    margin-top: -5rem !important;
  }

  .minus-mt-7 {
    margin-top: -7rem !important;
  }

  .minus-mt-10 {
    margin-top: -10rem !important;
  }

  .br-1 {
    border-radius: 0.55rem !important;
  }

  .br-2 {
    border-radius: 1rem !important;
  }

  .br-t-1 {
    border-top-left-radius: 0.55rem !important;
    border-top-right-radius: 0.55rem !important;
  }

  .br-t-2 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .b-r-grey {
    border-right: 1.7px solid #999;
  }

  .b-r-white {
    border-right: 1.7px solid #fff;
  }

  .b-l-white {
    border-left: 2.5px solid #fff;
  }

  .b-l-grey {
    border-left: 2.5px solid #999;
  }


  .bg-white {
    background: #fff;
  }

  .bg-blue {
    background-color: #2356b0;
  }

  .bg-red {
    background-color: #c0001f;
  }


  .check-radio {
    position: relative;
  }

  .check-radio input {
    -webkit-appearance: none;
    appearance: none;
    background-color: rgb(206, 8, 8);
    width: 40px;
    height: 15px;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, .5);
  }

  .check-radio input:focus {
    outline: none;
    cursor: pointer;
  }

  .check-radio input:before {
    content: '';
    margin-top: -2.5px;
    display: block;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #333, 0 2px 5px rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
  }

  .check-radio input:after {
    //content: 'FF';
    display: block;
    margin-top: -2.5px;
    left: 20px;
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    transition: left .3s ease-out;
    -webkit-transition: left .3s ease-out;
    color: #666;
  }

  .check-radio input:checked {
    background-color: lime;
  }

  .check-radio input:checked:before {
    left: 22.5px;
    box-shadow: inset 0 0 0 2px green, 0 2px 5px rgba(0, 0, 0, .5);
  }

  .check-radio input:checked:after {
    //content: 'N';
    left: 43.5px;
    color: green;
  }

  .btn-yellow {
    font-size: 1.2rem;
    width: 12rem;
    height: 2.5rem;
    background-color: #FCCD03 !important;
  }

  .btn-transparent{
    border-radius: 14pt;
    border: 1px solid #4D585A;
    background-color: transparent;
    color: #4D585A;
  }
  .btn-gray {
    font-size: 1.2rem;
    width: 12rem;
    height: 2.5rem;
    background-color: #bebebe !important;
    color: #333 !important;
  }

  .ion-page:has(#Lp_Inicial) {
    overflow: auto;
  } 
}